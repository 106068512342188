import React, { useState, useEffect }  from 'react';
import { Layout, Menu, Breadcrumb, message } from 'antd';
import { Link,withRouter } from 'react-router-dom';

import routeList from '../../route/Route'
import Tags from '../../components/Tags'
import './Layout.css';
import RouterMap from '../../route/index';
import {breadcrumbNameMap} from '../../route/routeName'
import { getRoleMenu, exitLogin, chooseSystermUrl, loginUrl } from '../../api/api'
import {LogoutOutlined,SwapOutlined} from '@ant-design/icons';
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;
export const btnRootContent = React.createContext()
const Layout_ = (props) => {
  const [collapsed, setCollapsed] = useState(false)
  const [selectedKeys, setselectedKeys] = useState('')
  const [roleList, setRoleList] = useState([])
  const [btnRoot,setbtnRoot] = useState([])
  const onCollapse = (collapsed ) => {
    setCollapsed(collapsed )
  }

  const queryName = 'token'
  let wloca = window.location
  const reg = new RegExp("/?" + queryName + "=([^#]*)(#|$)", "i");
  let r = wloca.href.match(reg);
  if ( r != null ){
    localStorage.setItem('tokens',decodeURI(r[1]))
    window.location.href = wloca.origin + '/'+ wloca.hash
  }

  const { location } = props;
  let tagList = sessionStorage.tagList ? JSON.parse(sessionStorage.tagList) : []
  const pathSnippets = location.pathname.split('/').filter(i => i);
  useEffect(() => {
    getRoleMenuList()
    setselectedKeys(pathSnippets[pathSnippets.length - 1])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props])

  const getRoleMenuList = () => { // 获取菜单
    const params = {
      appId: '9957305d3320e326255b8sce15c26199',
      requestType: 1
    }
    getRoleMenu(params).then(res => {
      if (res.code === 200) {
        let arr = []
        let btnRootArr = []
        res.data.forEach(item => {
          if(item.type === 3) {
            if(item.url) btnRootArr.push(item.url)
          }else {
            arr.push(item.url)
          }
        })
        setbtnRoot(btnRootArr)
        setRoleList(arr)
      } else {
        message.error(res.message)
      }
    }).catch(() => {
    })
  }
  
  //面包削(根据路由来做匹配的)
  let curUrl = '/'+pathSnippets.join('/')
  let curUrlName = breadcrumbNameMap[curUrl]
  if(curUrlName) {
    tagList.push(curUrlName)
    tagList = [...new Set(tagList)]
    sessionStorage.setItem('tagList',JSON.stringify(tagList))
  }

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    if(breadcrumbNameMap[url]) {
        return (
          <Breadcrumb.Item key={url}>
            {breadcrumbNameMap[url]}
          </Breadcrumb.Item>
        );
    }else {
      return null
    } 
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const menuClick = (item) => {
    setselectedKeys(item.key)
  }
  const exitLoginEvent = () => {
      //退出登录
      let params = {
        token: localStorage.tokens,
        requestType: 3
      }
      exitLogin(params).then(res => {
          if(res.code === 200) {
            localStorage.removeItem('tokens')
            window.location.href = loginUrl
          }
      })
  }
  const turnSysterm = () => {
    //切换系统
    window.location.href = chooseSystermUrl
  }
  return (
    <>
      <btnRootContent.Provider value={btnRoot}>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div className="logo" >LOGO</div>
          <Menu theme="dark" selectedKeys={[selectedKeys]} mode="inline" onClick={menuClick}>
            {
              routeList.map((item, index) => {
                return (
                  roleList.includes(item.name) ? 
                  (item.children && item.children.length > 0) ?
                    <SubMenu
                      key={item.name}
                      title={
                        <p>
                          {item.icon}
                          <span>{item.title}</span>
                        </p>
                      }
                    >
                      {
                        item.children.map((subItem, index) => {
                          return (
                            <Menu.Item key={subItem.name}>
                              <Link to={item.path}>
                                <p>
                                  {subItem.icon}
                                  <span>{subItem.title}</span>
                                </p>
                              </Link>
                                
                            </Menu.Item>
                          )
                        })
                      }
                    </SubMenu> :
                    <Menu.Item key={item.name}>
                      <Link to={item.path}>
                        <p>
                          {item.icon}
                          <span>{item.title}</span>
                        </p>
                      </Link>
                    </Menu.Item>:
                  null
                )
              })
            }
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="flex flex_cen bgf spaceBetween" style={{ padding: '10px' }} >
            <Breadcrumb style={{ margin: '0' }}>{breadcrumbItems}</Breadcrumb>
            <div className="otherHandle">
              <i onClick={() => turnSysterm()}><SwapOutlined />切换系统</i>
              <i onClick={() => exitLoginEvent()}><LogoutOutlined />退出</i>
            </div>
          </Header>
          <Tags list={tagList} curUrlName={curUrlName}></Tags>
          <Content style={{ margin: '10px 0 10px 10px', height: 'calc(100vh - 140px)', overflowY: 'scroll' }}>
            <RouterMap/>
          </Content>
        </Layout>
      </Layout>
      </btnRootContent.Provider>
    </>
  );
}

export default withRouter(Layout_)
