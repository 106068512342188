import axios from 'axios'
// import qs from 'qs';
import { message } from 'antd';
import {loginUrl} from '../api/api'
// const baseURL = 'http://10.10.22.174:8000/' // 测试
const baseURL = 'https://gateway.cdyushun.com/' // 正式
const Axios = axios.create({
  baseURL: baseURL,
  timeout: 15000 // 请求超时时间
})

// 添加请求拦截器
Axios.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  const types = config.data ? config.data.requestType : (config.params ? config.params.requestType : null)
  if(!types) {
      message.error('缺少服务类型参数！');
      return Promise.reject(false);
  }
  //types 1=>认证服务;2=>权限服务
  let url_prefix = types === 1 ? 'authorization' : (types === 2 ? 'permissions' : '')
  config.url = url_prefix + config.url
  
  if (config.method === 'post') {
      // config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
      // config.data = qs.stringify(config.data);
  }
  
  config.headers['Authorization'] = localStorage.tokens;
  // config.headers['Authorization'] = '252731a19da04716b16e30657e4078cd';
  //config.url =  basic.baseUrl + config.url;
  config.params = config.params || {};
  config.withCredentials = true;
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
Axios.interceptors.response.use(response => {
  // 对响应数据做点什么
  return response.data
}, error => {
  if(error.response.data.code === 401) {
    message.error('登录超时，请重新登录！')
    setTimeout(() => {
      window.location.href = loginUrl
    }, 2000);
  }
  return Promise.reject(error)
})

// 发起请求
export default (url, data, method = `GET`) => {
  data = data || ''
  if (method === `GET` || method === `get`) {
    return Axios.get(url, data.params ? data : {
      params: data
    })
  }
  if (method === 'delete' || method === 'DELETE') {
    return Axios.delete(url, data.params ? data : {
      params: data
    })
  }
  if (method === 'POST' || method === 'post') {
    return Axios.post(url, data)
  }
  if (method === 'patch' || method === 'PATCH') {
    return Axios.post(url, data)
  }
  if (method === 'put' || method === 'PUT') {
    return Axios.post(url, data)
  }
}
