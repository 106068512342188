import axios from '../axios/axios'

// export const loginUrl = 'http://10.10.22.174:8080/#/Login' // 登录测试
export const loginUrl = 'https://login.cdyushun.com/#/Login' // 登录正式
// export const chooseSystermUrl = 'http://10.10.22.174:8080/#/' //切换测试
export const chooseSystermUrl = 'https://login.cdyushun.com/#/' // 切换正式
export const exitLogin = (data) => { // 退出登录
  return axios(`/user/logout/web`, data, `post`)
}
export const getPositionInfoList = (data) => {//岗位列表
  return axios(`/positionInfo/getPositionInfoList`, data, `get`)
}
export const postSubCompany = (data) => {// 添加子公司
  return axios(`/subcompany/postSubCompany`, data, `post`)
}
export const getSubCompanyList = (data) => { // 获取公司下子公司列表信息
  return axios(`/subcompany/getSubCompanyList`, data, `get`)
}
export const postPositionInfo = (data) => {//新增岗位
  return axios(`/positionInfo/postPositionInfo`, data, `post`)
}
export const deletePositionInfo = (data) => {//删除岗位
  return axios(`/positionInfo/deletePositionInfo`, data, `get`)
}
export const updatePositionInfo = (data) => {//编辑岗位
  return axios(`/positionInfo/updatePositionInfo`, data, `post`)
}
export const getSysRoleList = (data) => {//角色列表
  return axios(`/sysRole/getSysRoleList`, data, `get`)
}
export const postSysRole = (data) => {//添加角色
  return axios(`/sysRole/postSysRole`, data, `post`)
}
export const deleteRoleById = (data) => {//删除角色
  return axios(`/sysRole/deleteRoleById`, data, `post`)
}
export const updateSysRole = (data) => {//修改角色
  return axios(`/sysRole/updateSysRole`, data, `POST`)
}
export const getSysAppList = (data) => {//获取应用列表
  return axios(`/sysApp/getSysAppList`, data, `get`)
}
export const getRoleMenuPermissionsByRoleId = (data) => {//根据不同的角色id获取当前角色下选择的菜单权限和数据权限
  return axios(`/sysRole/getRoleMenuPermissionsByRoleId`, data, `get`)
}
export const getAppMenuTreeByAppId = (data) => {//通过子系统id获取子系统菜单树
  return axios(`/sysMenu/getAppMenuTreeByAppId`, data, `get`)
}

export const getOrganizationByCompanyId = (data) => {//获取公司组织机构列表
  return axios(`/organization/getOrganizationByCompanyId`, data, `get`)
}
export const postOrganization = (data) => {//新增组织机构
  return axios(`/organization/postOrganization`, data, `post`)
}
export const updateOrganization = (data) => {//修改组织机构
  return axios(`/organization/updateOrganization`, data, `post`)
}
export const deleteOrganization = (data) => {//删除组织机构
  return axios(`/organization/deleteOrganization`, data, `delete`)
}




export const getOrganizationTreeByCompanyId = (data) => {//通过companyId获取组织机构树
  return axios(`/organization/getOrganizationTreeByCompanyId`, data, `get`)
}
export const getCompanyInfoList = (data) => { // 分页获取公司列表
  return axios(`/company/getCompanyInfoList`, data, `get`)
}
export const getSubCompanyById = (data) => { // 获取子公司信息
  return axios(`/subcompany/getSubCompanyById`, data, `get`)
}
export const updateSubCompany = (data) => { // 修改子公司信息
  return axios(`/subcompany/updateSubCompany`, data, `post`)
}
export const deleteSubCompany = (data) => { // 删除子公司
  return axios(`/subcompany/deleteSubCompany`, data, `post`)
}
export const getAppMenuTree = (data) => { // 获取菜单列表
  return axios(`/sysMenu/getAppMenuTree`, data, `get`)
}
export const getCompanyInfoById = (data) => { // 获取公司信息详情
  return axios(`/company/getCompanyInfoById`, data, `get`)
}
export const postCompanyInfo = (data) => { // 添加公司信息
  return axios(`/company/postCompanyInfo`, data, `post`)
}
export const updateCompanyInfo = (data) => { // 修改公司信息
  return axios(`/company/updateCompanyInfo`, data, `post`)
}
export const updateSysAppById = (data) => { // 修改系统应用
  return axios(`/sysApp/updateSysAppById`, data, `post`)
}
export const postSysApp = (data) => { // 添加系统应用
  return axios(`/sysApp/postSysApp`, data, `post`)
}
export const getSysAppById = (data) => { // 获取公司开通的应用
  return axios(`/sysApp/getSysAppById`, data, `get`)
}











export const getCompanyUserList = (data) => { // 分页获取公司用户列表
  return axios(`/user/getCompanyUserList`, data, `get`)
}
export const updateUserStatus = (data) => { // 更新用户账号状态
  return axios(`/user/updateUserStatus`, data, `post`)
}
export const getUserInfo = (data) => { // 获取用户账号及角色信息
  return axios(`/user/getUserInfo`, data, `get`)
}
export const updateUserInfo = (data) => { // 修改用户角色信息
  return axios(`/sysRole/updateUserInfo`, data, `post`)
}
export const postSysMenu = (data) => { // 添加系统菜单
  return axios(`/sysMenu/postSysMenu`, data, `post`)
}
export const updateSysMenu = (data) => { // 修改菜单
  return axios(`/sysMenu/updateSysMenu`, data, `post`)
}
export const getRoleMenu = (data) => { // 获取角色应用下菜单权限
  return axios(`/sysMenu/getRoleMenu`, data, `get`)
}