import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './pages/Layout/Layout'
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" component={Layout} />
        </Switch>
      </div>
    </Router>
    // <div className="App">
    //   <Layout></Layout>
    // </div>
  );
}

export default App;
