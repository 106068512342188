import React, { useState,useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Tag } from 'antd';
import {breadcrumbNameMap} from '../route/routeName'
import './tag.css'
const Tags = (props) => {
  const [tags, setTags] = useState([])
  const [checkTag,setCheckTag] = useState('首页')
  let history = useHistory();
  useEffect(() => {
    setTags(props.list)
    setCheckTag(props.curUrlName)
  },[props]);

  const handleClose = (removedTag,removedIndex) => {
    let tagList = tags.filter(tag => tag !== removedTag)
    if(tagList && tagList.length) {
        let checkedName = removedIndex === 0 ? tagList[0] : tagList[removedIndex - 1]
        let checkdUrl = Object.keys(breadcrumbNameMap).filter(key => breadcrumbNameMap[key] === checkedName)
        setTags(tagList);
        setCheckTag(checkedName)
        if(checkdUrl && checkdUrl.length) history.push(checkdUrl[0]);
        sessionStorage.setItem('tagList',JSON.stringify(tagList))
    }else {
        sessionStorage.setItem('tagList',JSON.stringify(['子系统管理']))
        setTags(['子系统管理']);
        setCheckTag('子系统管理')
        history.push("/Home");
    }
  };
  const toPath = (tagname) => {
    let toPathUrl = ''
    Object.keys(breadcrumbNameMap).map(k => {
        if(breadcrumbNameMap[k] === tagname) {
          toPathUrl = k
        }
        return ''
    })
    if(toPathUrl) history.push(toPathUrl);
  }

  return (
    <>
      <div className="tagContainer">
        {tags.length && 
          tags.map((tag, index) => {
            return <Tag
              className="edit-tag mr30"
              key={index}
              closable
              onClick={() => toPath(tag)}
              onClose={e => {
                e.preventDefault();
                handleClose(tag,index)
              }}
              color={tag === checkTag ? "processing" : "default"}
          >{tag}</Tag>
          })
        }
      </div>
    </>
  )
}

export default Tags