/*
 * @Description: 路由集中整理，这里的只是显示在Layout组件里面的路由，通过登录、权限动态生成路由
 * @Author: zhangping
 * @Date: 2019-06-11 20:08:52
 * @LastEditTime: 2019-06-25 11:56:44
 * @LastEditors: Please set LastEditors
 */
import React from 'react';
import { lazy } from 'react'
import { DeploymentUnitOutlined, ContainerOutlined, EuroCircleOutlined, CopyrightOutlined, 
          UsergroupDeleteOutlined, TeamOutlined, SkinOutlined, IdcardOutlined } from '@ant-design/icons'

/**
 * 路由懒加载
 * @param {String} filename 文件路径
 */
const lazyRouter = (filename) => {
  return lazy(() => import(`../pages/${filename}`))
}

/**
 * path: string-访问的路径
 * name: string-名称
 * component: ReactElement-应用的组件
 * needLogin: boolean-是否需要登录
 * redirect: string-重定向
 * auth: Array-能访问该路由的权限（没有就证明没有权限限制），auth: ['admin', 'user']
 */
const RouterMap = [
  { 
    path: '/',
    name: '',
    title: '首页',
    component: lazyRouter('Home/Index'),
  },
  { 
    path: '/Home',
    name: 'Home',
    icon: (<DeploymentUnitOutlined />),
    title: '子系统管理',
    component: lazyRouter('Home/Home'),
  },
  {
    path: '/Menu_manage',
    name: 'Menu_manage',
    icon: (<ContainerOutlined />),
    title: '菜单管理',
    component: lazyRouter('Menu_manage/Menu_manage')
  },
  {
    path: '/Subsidiary',
    name: 'Subsidiary',
    icon: (<EuroCircleOutlined />),
    title: '子公司管理',
    component: lazyRouter('Subsidiary/Subsidiary')
  },
  {
    path: '/Settled',
    name: 'Settled',
    icon: (<CopyrightOutlined />),
    title: '入驻公司管理',
    component: lazyRouter('Settled/Settled')
  },
  {
    path: '/customer',
    name: 'customer',
    icon: (<UsergroupDeleteOutlined />),
    title: '用户管理',
    component: lazyRouter('Customer/Index')
  },
  {
    path: '/Organization_manage',
    name: 'Organization_manage',
    icon: (<IdcardOutlined />),
    title: '组织机构管理',
    component: lazyRouter('Organization_manage/Organization_manage')
  },
  {
    path: '/Post_manage',
    name: 'Post_manage',
    icon: (<SkinOutlined />),
    title: '岗位管理',
    component: lazyRouter('Post_manage/Post_manage')
  },
  {
    path: '/Role_manage',
    name: 'Role_manage',
    icon: (<TeamOutlined />),
    title: '角色管理',
    component: lazyRouter('Role_manage/Role_manage')
  },
  // {
  //   path: '/system', name: 'system', title: '系统管理', component: lazyRouter('mine/Index'),
  //   children: [
  //     {
  //       name: 'account',
  //       path: '/account',
  //       title: '账号管理',
  //     },
  //     {
  //       name: 'role',
  //       path: '/role',
  //       title: '角色管理',
  //     },
  //     {
  //       name: 'Permission',
  //       path: '/Permission',
  //       title: '权限管理',
  //     },
  //     {
  //       name: 'menu',
  //       path: '/menu',
  //       title: '菜单管理',
  //     },
  //     {
  //       name: 'log',
  //       path: '/log',
  //       title: '操作日志',
  //     }
  //   ]
  // },
]

export default RouterMap