import React from 'react'
import { HashRouter as Router, Route } from 'react-router-dom';
import LoadableComponent from './LoadableComponent'
//懒加载
const H = LoadableComponent(()=>import('../pages/Home/Index'))  
const Home = LoadableComponent(()=>import('../pages/Home/Home'))  
const Menu_manage = LoadableComponent(()=>import('../pages/Menu_manage/Menu_manage'))
const Organization_manage = LoadableComponent(()=>import('../pages/Organization_manage/Organization_manage')) 
const Subsidiary = LoadableComponent(()=>import('../pages/Subsidiary/Subsidiary'))
const Post_manage = LoadableComponent(()=>import('../pages/Post_manage/Post_manage'))  
const Role_manage = LoadableComponent(()=>import('../pages/Role_manage/Role_manage'))  
const Customer = LoadableComponent(()=>import('../pages/Customer/Index'))  
const Settled = LoadableComponent(()=>import('../pages/Settled/Settled'))

export default () => (
  <Router>
    <Route path="/" component={H} />
    <Route path="/Home" component={Home} />
    <Route path="/Menu_manage" component={Menu_manage} />
    <Route path="/Subsidiary" component={Subsidiary} />
    <Route path="/Organization_manage" component={Organization_manage} />
    <Route path="/Post_manage" component={Post_manage} />
    <Route path="/Role_manage" component={Role_manage} />
    <Route path="/customer" component={Customer} />
    <Route path="/Settled" component={Settled} />
  </Router>
);