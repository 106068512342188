import * as Loadable from 'react-loadable'
import * as NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import React, { useEffect } from 'react';

const LoadingPage = () => {
    NProgress.start()
    useEffect(() => {
        NProgress.done()
    })
    return (
        <div/>
    )
}
const LoadableComponent = (component) => {
  return Loadable({
    loader: component,
    loading: ()=><LoadingPage/>
  })
}

export default LoadableComponent