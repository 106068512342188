export const breadcrumbNameMap = {
    '/': '首页',
    '/Home': '子系统管理',
    '/Menu_manage': '菜单列表',
    '/Organization_manage':'组织机构管理',
    '/Subsidiary':'子公司管理',
    '/Post_manage':'岗位管理',
    '/Role_manage':'角色管理',
    '/customer':'用户管理',
    '/Settled':'入驻公司管理'
  };